import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import styles from './feature.module.scss';

class Feature extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ScrollAnimation className={styles.feature + " " + (this.props.centered ? styles.centered : '')} animateIn="fadeInUp" animateOnce={true}>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={250}>
          <div className="mb-2 d-flex justify-content-center align-items-center" style={{maxHeight: 250}}>
            {this.props.icon}
          </div>
          <h5>{this.props.title}</h5>
          <p>{this.props.copy}</p>
          {/* <Link to="/" className={`btn btn-primary btn-sm ` + styles.btn}>
            More
          </Link> */}
        </ScrollAnimation>
      </ScrollAnimation>
    )
  }
}
export default Feature