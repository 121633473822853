import React from "react"
import { graphql } from "gatsby"
import ScrollAnimation from 'react-animate-on-scroll';
import Img from "gatsby-image";
import Helmet from 'react-helmet'
import "../styles/index.scss";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Heading from "../components/heading/heading";
import ContentBlock from "../components/contentBlock/contentBlock"
import Feature from "../components/feature/feature";

import CardX from "../images/card-X.inline.svg";
import CartX from "../images/cart-X.inline.svg";
import PersonX from "../images/person-X.inline.svg";
import RegisterX from "../images/register-X.inline.svg";
import TagX from "../images/tag-X.inline.svg";
import OpenApiIcons from "../images/open-api-icons.inline.svg";

const PlatformsPage = ({ data }) => {
  console.log(data);

  return (
    <Layout>
      <SEO title="Platform" />
      <Helmet bodyAttributes={{class: 'platforms-page overflow-x-hidden'}} />
      <ScrollAnimation className="mt-5" animateIn="fadeInUp" animateOnce={true} style={{marginBottom: 150}}>
        <Heading
          title="Platform"
          copy="The RetailStack Commerce Platform recognizes that sales happen at more than just a fixed position within your store. The platform reimagines the enterprise point of a sale as a holistic commerce platform that provides enterprise with a rich set of out-of-the-box capabilities, an open platform for first- and third-party integrations and a unified view of customers and their transactions."
          align="center"
        />
        <Img fluid={data.file.childImageSharp.fluid} alt="platform header" />
      </ScrollAnimation>
      <div className="content-blocks"> 
          <ContentBlock
            imageCol="right"
            title="Customer Centric"
            copy="Traditional point of sale systems focus on the transaction first and therefore lose out on the deep insights to be gained by prioritizing customers. Through integrated loyalty capabilities, the platform is designed to help you better understand the who, what, when and why of your customers purchasing behaviors and motivate activities that result in increased buyer satisfaction and income."
            imageName="home-customer-centric.png"
          />
          <ContentBlock
            imageCol="left"
            title="Robust Features"
            copy="The monolithic, closed platform point of sale no longer works for the modern enterprise. That’s why we’re reimagining how modern commerce can work for your business through a flexible architecture approach with native cloud capabilities. The rich set of out-of-the-box features include solutions that will save you time and money, enable you to respond more quickly to the ever changing business climate and stay up to date with the technology of both today and tomorrow."
            imageName="home-pos-for-today.png"
          />
        </div>
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <Heading
            title="Core Features"
            copy="The RetailStack Commerce platform stacks an industry-specific implementation layer on top of a robust unified commerce platform built with four main features."
            align="center"
          />
        </ScrollAnimation>
        <div className="row" style={{marginBottom: 100}}>
          <div className="col-sm-12 col-md-3">
            <Feature 
              icon={<PersonX/>}
              title="Loyalty"
              copy="Capture information on your customers, their behaviors, and motivations to drive additional engagement and purchase activity."
              centered={true}
            />
          </div>
          <div className="col-sm-12 col-md-3">
            <Feature 
              icon={<TagX/>}
              title="Products"
              copy="Tight integration of your product offering across both physical and digital commerce channels."
              centered={true}
            />
          </div>
          <div className="col-sm-12 col-md-3">
            <Feature 
              icon={<CartX/>}
              title="Transactions"
              copy="Capture traditional and digital sales in a unified platform, offering a single source of truth, regardless of the payment method or delivery channel."
              centered={true}
            />
          </div>
          <div className="col-sm-12 col-md-3">
            <Feature 
              icon={<CardX/>}
              title="Stored Value"
              copy="Avoid incremental fees with a built-in proprietary stored value system that helps you manage a robust gift card, store credit or cash back program."
              centered={true}
            />
          </div>
        </div>
        <ScrollAnimation className="row greenBg" animateIn="fadeInUp" animateOnce={true} style={{marginBottom: 150}}>
          <div className="col-sm-12 col-md-9 pl-5 ml-5 pl-sm-0 ml-sm-0">
            <Heading
              title="Open Platform"
              copy="In addition to robust built-in features, the platform of the future more tightly integrates with your existing business processes by allowing first-party software and reporting tools direct access to your commerce engine. Expanding the capabilities of the platform is simple with APIs that support integrations with existing businesses or processes or speciality functionality and partners like third-party delivery service providers, speciality payment acceptance, and more."
              styleKey="secondary"
              white={true}
              marginBottom={5}
            />
            <OpenApiIcons />
          </div>
        </ScrollAnimation>
    </Layout>
  )
}

export default PlatformsPage

export const query = graphql`
  query {
    file(relativePath: { eq: "platform-header.png" }) {
      childImageSharp {
        fluid(maxWidth: 1170) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
